import React from 'react';
import Logo from './subcomponents/Logo';

const Home = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center pb-5">About the Dev</h1>
      <div className="row align-items-center">
        {/* Profile Image Section */}
        <div className="col-md-3 text-center">
          <img
            src="images/pfp.jpg"
            alt="Developer Profile"
            className="img-fluid rounded-circle mb-3"
          />
          <h4>Alex Wang</h4>
          <p>Developer</p>
        </div>
        {/* Developer Bio Section */}
        <div className="col-md-9 px-5">
          <p>
            Hello! I'm Alex Wang, a passionate full-stack developer from New Orleans with hands-on experience solving real-world coding challenges. I like designing efficient and creative programmatic solutions that enhance functionality and user experience. I'm driven by a curiosity for learning. As a student at Case Western Reserve University, where I'm pursuing a B.S. in Software Engineering with a secondary major in Economics, I combine my technical mindset with an analytical perspective.
          </p>
          <p>
            Outside the world of code, I'm actively involved in campus life, dedicating time to volunteering and working out. I'm also a horror movie enthusiast, rock-climbing beginner, and an avid reader who enjoys exploring everything from tech blogs to fantasy novels. Music is another big passion—I'm always on the hunt for fresh tracks to add to my playlists.
          </p>
          <p>
            If you want to chat about software development or simply want to reach out, I'd love to connect. Feel free to reach out at <a href="mailto:walex11303@gmail.com">walex11303@gmail.com</a> or my <a href="https://www.linkedin.com/in/alex-11303-wang">Linkedin</a>.
          </p>
        </div>

      </div>

      {/* Technologies Section */}
      <div className="mt-5">
        <h2 className="text-center">Technologies I Work With</h2>
        <div className="row text-center align-items-center pb-5 pt-5 row-gap-4">
          <Logo name="MongoDB.js" path="images/logo-mongodb.png" />
          <Logo name="Express.js" path="images/logo-expressjs.png" />
          <Logo name="React.js" path="images/logo-reactjs.png" />
          <Logo name="Node.js" path="images/logo-nodejs.png" />
          <Logo name="Python" path="images/logo-python.png" />
          <Logo name="R" path="images/logo-r.png" />
          <Logo name="Matlab" path="images/logo-matlab.png" />
          <Logo name="Java" path="images/logo-java.png" />
        </div>
      </div>

      {/* Experience Section */}
      {/* <div className="mt-5">
        <h3>Projects & Experience</h3>

        <div className="project">
          <p>
            <span className="fw-bold">Google API Spreadsheet Bot.</span> Developed a financial bot that automates budget
            tracking for the University Finance Committee, updating revenue and purchase data in real time. The bot's
            security features adapt user access based on roles, ensuring data protection for sensitive information.
          </p>
        </div>

        <div className="project">
          <p>
            <span className="fw-bold">Scheme Language Interpreter.</span> Built a Scheme-like interpreter capable of
            handling complex language structures, including recursive functions and exception management. Leveraged
            tail call optimization and rigorous testing to ensure consistent handling of parameter mismatches and
            function closures.
          </p>
        </div>

        <div className="project">
          <p>
            <span className="fw-bold">Eight Puzzle Solver.</span> Created a GUI-based puzzle solver using the PyGame
            library, implementing the A* algorithm to find optimal solutions quickly. Enhanced the user experience
            with intuitive controls and a heuristic approach, which reduced search times and improved interaction.
          </p>
        </div>

        <div className="experience">
          <p>
            <span className="fw-bold">Generative AI Externship Project.</span> Conducted accuracy comparisons
            across convolutional neural networks and presented findings to peers and mentors. Applied efficient
            fine-tuning techniques to improve model performance with minimal resources, contributing to advancements
            in generative AI.
          </p>
        </div>

      </div> */}

      {/* Resume Section */}
      {/* <div className="mt-5">
        <h3>Resume</h3>
        <p>
          Interested in seeing my full experience and projects? Download my resume below:
        </p>
        <a href="images/WangAlexResume.pdf" className="btn btn-secondary" download>Download Resume</a>
      </div> */}
    </div >
  );
};

export default Home;


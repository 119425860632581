import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';

const Form = (props) => {
    const [validation, setValidation] = useState({ isValid: false, errors: {} });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { setProfileData } = useAppContext();
    const navigate = useNavigate();

    const isLogin = props.action === 'Login';
    const isRegister = props.action === 'Register';


    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasNoWhitespace = !/\s/.test(password);
        const isValid =
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumber &&
            hasNoWhitespace;

        return {
            isValid,
            errors: {
                minLength: password.length >= minLength,
                hasUpperCase,
                hasLowerCase,
                hasNumber,
                hasNoWhitespace,
            },
        };
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setValidation(validatePassword(newPassword));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setError('Please fill out all fields');
        } else if (!validation.isValid && isRegister) {
            const failedRequirements = [];
            if (!validation.errors.minLength) failedRequirements.push('minimum length of 8 characters');
            if (!validation.errors.hasUpperCase) failedRequirements.push('at least one uppercase letter');
            if (!validation.errors.hasLowerCase) failedRequirements.push('at least one lowercase letter');
            if (!validation.errors.hasNumber) failedRequirements.push('at least one number');
            if (!validation.errors.hasNoWhitespace) failedRequirements.push('no spaces');

            setError(`Password does not meet the following requirement${failedRequirements.length !== 1 && 's'}: `);
            setError(err => err + `${failedRequirements.join(', ')}.`);
        } else {
            setError('');

            try {
                const response = await fetch(
                    `https://alexwebserver.onrender.com/auth/${props.action.toLowerCase()}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ username, password }),
                    }
                );
                const data = await response.json();
                if (response.ok) {
                    if (isLogin) {
                        localStorage.setItem('token', data.token);
                        setProfileData(data);
                        navigate('/'); // Go to home
                    }
                    if (isRegister)
                        navigate('/login');
                } else {
                    setError(`Error: Username taken.`);
                }
            } catch (error) {
                setError(`Error.`);
            }
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <h2 className="text-center text-capitalized">{props.action}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                            />
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <button
                            type="submit"
                            className="btn w-100 fw-bold"
                            style={{ backgroundColor: '#32a852', color: 'white' }}
                        >
                            {props.action}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Form;
import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../styles/Dash.css';

const MAX_LENGTH = 24;
const MAX_HEIGHT = 8;
const MIN_LENGTH = 1;

const Dash = () => {
    const [height, setHeight] = useState(1);
    const [length, setLength] = useState(1);

    useEffect(() => {
        const handleKeyInput = (e) => {
            const key = e.key?.toUpperCase();

            if (key === 'ARROWUP') setHeight(x => (x >= MAX_HEIGHT) ? MAX_HEIGHT : x + 1);
            if (key === 'ARROWDOWN') setHeight(x => (x <= MIN_LENGTH) ? 0 : x - 1);
            if (key === 'ARROWLEFT') setLength(x => (x <= MIN_LENGTH) ? 0 : x - 1);
            if (key === 'ARROWRIGHT') setLength(x => (x >= MAX_LENGTH) ? MAX_LENGTH : x + 1);
        }

        window.addEventListener('keydown', handleKeyInput);

        return () => window.removeEventListener('keydown', handleKeyInput);
    });

    return (
        <Container className="mt-5">
            <p className="center-text">{`length: ${length}; height: ${height}`}</p>
            <div className="board">
                {Array.from({ length: height }).map((_, row) => (
                    <Row key={row}>
                        {Array.from({ length: length }).map((_, col) => {
                            return (
                                <Col
                                    key={col}
                                    className="square"
                                >
                                </Col>
                            );
                        })}
                    </Row>
                ))}
            </div>
        </Container>
    );
};

export default Dash;
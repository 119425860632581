import { AreaChart, Area, CartesianGrid, YAxis, XAxis, Tooltip, Label } from 'recharts';

const ActivityChart = (props) => {
    return (
        <div className="chart">
            <p className="text-center lead fs-3 pt-3">{props.title}</p>
            <AreaChart
                width={500}
                height={200}
                data={props.data}
                margin={{ top: 10, right: 10, left: 10, bottom: 30 }} // Increased margins to show labels
                className="mx-auto"
            >
                {/* Define colors */}
                <defs>
                    <linearGradient id="colorHit" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="green" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="green" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorMiss" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="crimson" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="crimson" stopOpacity={0} />
                    </linearGradient>
                </defs>

                {/* Define grid */}
                <CartesianGrid strokeDasharray="3 3" />

                {/* Define axes */}
                <XAxis
                    dataKey="timestamp"
                    type="number"
                    scale="time"
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={(tick) => new Date(tick).toUTCString().slice(0, 16)} // Format tick values as dates
                >
                    <Label value="Date" position="bottom" offset={0} style={{ textAnchor: 'middle' }} />
                </XAxis>
                <YAxis>
                    <Label value="Words" position="left" angle={-90} offset={0} style={{ textAnchor: 'middle' }} />
                </YAxis>

                {/* Define tooltip */}
                <Tooltip
                    labelFormatter={(label) => `${new Date(label).toUTCString().slice(0, 16)}`} // Format date label
                    formatter={(value, name) => {
                        if (name === 'hits') return [value, 'Words Solved'];
                        if (name === 'misses') return [value, 'Words Skipped/Revealed'];
                        return value;
                    }}
                />

                <Area type="monotone" dataKey="misses" stackId={0} stroke="crimson" fillOpacity={1} fill="url(#colorMiss)" />
                <Area type="monotone" dataKey="hits" stackId={1} stroke="green" fillOpacity={1} fill="url(#colorHit)" />
            </AreaChart>
        </div>
    );
};

export default ActivityChart;
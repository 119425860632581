import React, { useEffect, useState } from 'react';
import { useAppContext } from '../AppContext';
import { Container } from 'react-bootstrap';
import ActivityChart from './charts/ActivityChart';

const Profile = () => {

    const { profileData, setProfileData } = useAppContext();
    const [isLoaded, setIsLoaded] = useState(false);
    const [anagramData, setAnagramData] = useState([]);
    const [crosswordData, setCrosswordData] = useState([]);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://alexwebserver.onrender.com/auth/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Add token to header
                        'Content-Type': 'application/json'
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setProfileData(data);
                    setIsLoaded(true);
                } else {
                    setProfileData([]);
                }
            } catch (error) {
                setIsLoaded(false);
                console.log('Error fetching profile');
            }
        }

        fetchProfile();
    }, []);

    useEffect(() => {
        if (isLoaded && profileData) {
            // Set anagram data
            if (anagramData.length < 2) {
                setAnagramData(profileData.stats.anagram);
            } else {
                setAnagramData([]);
            }
        }
    }, [isLoaded]);

    useEffect(() => {
        if (isLoaded && profileData) {
            // Set anagram data
            if (crosswordData.length < 2) {
                setCrosswordData(profileData.stats.crossword);
            } else {
                setCrosswordData([]);
            }
        }
    }, [isLoaded]);

    if (!isLoaded) return;

    return (
        <Container fluid>
            <div className="row">
                <div className="col">
                    <ActivityChart className="col-4" data={anagramData} title="Anagram Stats" />
                </div>
                <div className="col">
                    <ActivityChart className="col-4" data={crosswordData} title="Crossword Stats" />
                </div>
            </div>
        </Container>
    );
};

export default Profile;
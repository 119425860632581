const Footer = () => {
    return (
        <footer
          className="d-flex flex-wrap justify-content-between align-items-center bg-dark p-3"
          style={{ color: 'rgba(255,255,255,0.75)' }}
        >
          <span>© 2024</span>

          <ul className="nav justify-content-end list-unstyled">
            <li>
              <div>- follow me -</div>
              <div className="small">icons by icons8</div>
            </li>
            <li className="ms-3"><a target="_blank" href="https://github.com/alex-wang-13"><img alt="Github Logo" src="images/icons8-github-48.png" /></a></li>
            <li className="ms-3"><a target="_blank" href="https://www.linkedin.com/in/alex-11303-wang"><img alt="Linkedin Logo" src="images/icons8-linkedin-48.png" /></a></li>
            <li className="ms-3"><a target="_blank" href="https://letterboxd.com/alexthedawg"><img alt="Letterboxd Logo" src="images/icons8-letterboxd-48.png" /></a></li>
            <li className="ms-3"><a target="_blank" href="https://www.goodreads.com/user/show/57369610-alex"><img alt="Goodreads Logo" src="images/icons8-goodreads-48.png" /></a></li>
          </ul>
        </footer>
    );
};

export default Footer;
const Logo = (props) => {
    return (
        <div className="col-md-3 mb-4 px-3">
            <div 
                className="text-center d-flex flex-column align-items-center p-3" 
                style={{ borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", backgroundColor: "#f9f9f9" }}
            >
                <img 
                    src={props.path} 
                    alt={`${props.name} Logo`} 
                    className="img-fluid " 
                    style={{ width: "120px", height: "120px", transition: "transform 0.3s ease" }} 
                    onMouseOver={(e) => e.target.style.transform = "scale(1.1)"} 
                    onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
                <p className="mt-3 mb-0" style={{ fontWeight: "500", fontSize: "1.1rem", color: "#333" }}>{props.name}</p>
            </div>
        </div>
    );
};

export default Logo;
